<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editTournament') }}</h5>
                <div class="p-field p-grid" v-show="this.isEdit()">
                    <label for="sportId" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="text" v-model="tournament.id" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sportName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportName" type="text" v-model="tournament.name" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.displayName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="displayName" type="text" v-model="tournament.displayName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sortOrder" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sortOrder" type="text" v-model="tournament.sortOrder" />
                    </div>
                </div>
                <div class="p-field p-grid" v-show="!this.isEdit()">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.sport') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid" v-show="!this.isEdit()">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.category') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="categoriesModel" :options="categories" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.category')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.marketTemplate') }}</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown :showClear="true" style="width: 100%" v-model="marketTemplatesModel" :options="marketTemplates" optionLabel="name" :placeholder="$t('dropdown.placeholder.marketTemplate')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.oddsTemplate') }}</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown :showClear="true" style="width: 100%" v-model="oddsTemplatesModel" :options="oddsTemplates" optionLabel="name" :placeholder="$t('dropdown.placeholder.oddsTemplate')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.printTemplate') }}</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown :showClear="true" style="width: 100%" v-model="printTemplatesModel" :options="printTemplates" optionLabel="name" :placeholder="$t('dropdown.placeholder.printTemplate')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }} </Message>
                </transition-group>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            tournament: {},
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'tournaments',
            sports: [],
            sportsModel: {},
            categories: [],
            categoriesModel: {},
            showCategoriesDdl: false,
            marketTemplates: [],
            marketTemplatesModel: {},
            printTemplates: [],
            printTemplatesModel: {},
            oddsTemplates: [],
            oddsTemplatesModel: {},
            errorKeyCount: 0,
        };
    },
    mounted() {
        if (this.isEdit()) {
            this.SportsSettingsApi.getTournament(this.$route.params.id)
                .then((response) => {
                    this.tournament = response.data;
                    this.getAllTemplates();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                    this.errorText = error;
                    this.showError = true;
                });
        } else {
            this.SportsSettingsApi.getAllSports()
                .then((response) => {
                    this.sports = response.data;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
            this.getAllTemplates();
        }
    },
    watch: {
        sportsModel() {
            this.tournament.sportId = this.sportsModel.id;
            this.getCategories(this.sportsModel.id);
        },
        categoriesModel() {
            this.tournament.categoryId = this.categoriesModel.id;
        },
    },
    methods: {
        submitForm() {
            this.disableSubmit = true;
            this.showError = false;
            this.errorText = '';
            if (
                this.notNullOrEmptyObject(this.tournament) &&
                this.notNullOrEmptyField(this.tournament.name) &&
                this.notNullOrEmptyField(this.tournament.displayName) &&
                this.notNullOrEmptyField(this.tournament.sortOrder) &&
                this.notNullOrEmptyField(this.tournament.sportId) &&
                this.notNullOrEmptyField(this.tournament.categoryId)
            ) {
                if (this.notNullOrEmptyObject(this.marketTemplatesModel) && this.notNullOrEmptyField(this.marketTemplatesModel._id)) {
                    this.tournament.marketTemplate = this.marketTemplates._id;
                } else {
                    this.tournament.marketTemplate = null;
                }
                if (this.notNullOrEmptyObject(this.printTemplatesModel) && this.notNullOrEmptyField(this.printTemplatesModel._id)) {
                    this.tournament.printTemplate = this.printTemplatesModel._id;
                } else {
                    this.tournament.printTemplate = null;
                }
                if (this.notNullOrEmptyObject(this.oddsTemplatesModel) && this.notNullOrEmptyField(this.oddsTemplatesModel._id)) {
                    this.tournament.oddsTemplate = this.oddsTemplatesModel._id;
                } else {
                    this.tournament.oddsTemplate = null;
                }
                this.SportsSettingsApi.updateTournament(this.tournament)
                    .then(() => {
                        this.goto(this.backRoute);
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.errorText = error;
                        this.showError = true;
                        this.errorKeyCout++;
                    });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        getCategories(sportId) {
            this.SportsSettingsApi.getAllCategories(sportId).then((response) => {
                this.categories = response.data;
                this.showCategoriesDdl = true;
            });
        },
        getAllTemplates() {
            this.SportsSettingsApi.getMarketTemplates('0').then((marketResponse) => {
                this.marketTemplates = marketResponse.data;
                if (this.isEdit() && this.tournament.marketTemplate != null) {
                    this.marketTemplatesModel = this.marketTemplates.find((elem) => elem._id == this.tournament.marketTemplate);
                }
            });
            this.SportsSettingsApi.getPrintTemplates().then((printTemplateResponse) => {
                this.printTemplates = printTemplateResponse.data;
                if (this.isEdit() && this.tournament.printTemplate != null) {
                    this.printTemplatesModel = this.printTemplates.find((elem) => elem._id == this.tournament.printTemplate);
                }
            });
            this.SportsSettingsApi.getOddsTemplates().then((oddsTemplateResponse) => {
                this.oddsTemplates = oddsTemplateResponse.data;
                if (this.isEdit() && this.tournament.oddsTemplate != null) {
                    this.oddsTemplatesModel = this.oddsTemplates.find((elem) => elem._id == this.tournament.oddsTemplate);
                }
            });
        },
    },
};
</script>
